

























































































import Vue from "vue";
import { mapState } from "vuex";
import moment, { Moment } from "moment";
import { CouponActivityType } from "@/enum";
import { dispatch, ListQuery, CouponActivity } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return {
      dispatch,
      mallCouponActivity: url.mallCouponActivity,
      loading: false,
      form: this.$form.createForm(this),
      visible: false,
      isAdd: false,
      model: {} as CouponActivity
    };
  },
  computed: {
    ...mapState("coupons", {
      list: "activityList",
      query: "activityQuery",
      count: "activityCount"
    }),
    fields() {
      return [
        {
          title: "活动标题",
          dataIndex: "title",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动标题",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "活动描述",
          dataIndex: "description",
          type: "textarea",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动描述",
              trigger: "blur"
            },
            {
              max: 400,
              message: "请输入少于 400 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "活动 ID",
          dataIndex: "custom_id",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动 ID",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            },
            {
              validator: (rule: any, value: string, callback: Function) => {
                if (!/^[A-Za-z0-9_]*$/.test(value)) {
                  callback("活动 ID 只能为英文和数字组合");
                  return;
                }
                callback();
              }
            }
          ]
        },
        {
          title: "发放总数",
          dataIndex: "total_count",
          type: "number",
          min: 1,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入发放总数"
            }
          ]
        },
        {
          title: "领取方式",
          dataIndex: "activity_type",
          type: "radio",
          options: CouponActivityType,
          rules: [
            {
              required: true,
              message: "请选择领取方式"
            }
          ],
          immutable: true,
          customRender: (text: string) => (CouponActivityType as any)[text]
        },
        {
          title: "开始时间",
          dataIndex: "start_at",
          type: "datetime",
          rules: [
            {
              required: true,
              message: "请选择开始时间"
            }
          ],
          change: (m: Moment, value: string) => {
            this.model.start_at = value;
          },
          scopedSlots: { customRender: "time" }
        },
        {
          title: "结束时间",
          dataIndex: "end_at",
          type: "datetime",
          rules: [
            {
              required: true,
              message: "请选择结束时间"
            },
            {
              validator: (rule: any, value: number, callback: Function) => {
                if (moment(value).isBefore(moment(this.model.start_at))) {
                  callback("结束时间大于开始时间");
                  return;
                }
                callback();
              }
            }
          ],
          scopedSlots: { customRender: "time" }
        }
        // {
        //   title: "是否开启",
        //   dataIndex: "is_active",
        //   type: "switch",
        //   scopedSlots: { customRender: "active" }
        // }
      ];
    },
    columns() {
      let columns: any = this.fields.slice();
      columns.splice(1, 1);
      columns = columns.concat([
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "350px"
        }
      ]);
      return columns;
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetActivityList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onAdd() {
      this.visible = true;
      this.isAdd = true;
      this.model = { is_active: true } as CouponActivity;
    },
    onEdit(couponActivity: CouponActivity) {
      this.visible = true;
      this.isAdd = false;
      this.model = couponActivity;
    },
    onOk() {
      this.visible = false;
      this.model = {} as CouponActivity;
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter
      });
    },
    onShow(couponActivity: CouponActivity) {
      this.$router.push(
        this.$paths.coupon.replace(":id", couponActivity.id + "")
      );
    },
    onShowClaim(couponActivity: CouponActivity) {
      dispatch
        .couponsGetActivityClaimInfo(couponActivity.id)
        .then(res => {
          this.$info({
            title: `券码活动 - ${couponActivity.title}`,
            content: (h: Function) =>
              h("div", [
                h("div", "已发放券码数量：" + res.claimed),
                h("br"),
                h("div", "此数量包含:"),
                h("div", "1. 用户直接领取的券码数量"),
                h("div", "2. 已经批量生成的券码总数")
              ]),
            centered: true
          });
        })
        .catch(() => {
          this.$message.destroy();
          this.$message.info("暂无领取信息");
        });
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onActiveChange(couponActivity: CouponActivity) {
      couponActivity.is_active = !couponActivity.is_active;
      this.$confirm({
        title: `确认删除优惠活动 【${couponActivity.title}】？`,
        content: "删除后已领取的券码还可在有效期内继续使用",
        onOk: () => {
          couponActivity.is_active = false;
          dispatch.couponsPatchActivity(couponActivity).then(() => {
            this.getList(this.query);
          });
        }
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
