var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coupon-activity-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" "),_c('a-button',{attrs:{"type":"primary","shape":"round","icon":"plus"},on:{"click":_vm.onAdd}},[_vm._v(" 添加优惠活动 ")]),_c('v-form-modal',{attrs:{"name":"优惠活动","visible":_vm.visible,"isAdd":_vm.isAdd,"fields":_vm.fields,"model":_vm.model,"add":_vm.dispatch.couponsPostActivity,"edit":_vm.dispatch.couponsPatchActivity,"width":"700px","labelCol":{ span: 6 },"wrapperCol":{ span: 14 }},on:{"ok":_vm.onOk,"cancel":function($event){_vm.visible = false}}})],1),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"活动标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title__icontains']),expression:"['title__icontains']"}],staticClass:"form-field",attrs:{"placeholder":"请输入活动标题"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('div',{staticClass:"actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]),_c('a-button',{on:{"click":_vm.onFilterReset}},[_vm._v(" 清空条件 ")])],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"active",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text},on:{"change":function($event){return _vm.onActiveChange(record)}}})]}},{key:"time",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")]}},{key:"actions",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.onShow(record)}}},[_vm._v("管理券码")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.onShowClaim(record)}}},[_vm._v("领取情况")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.onActiveChange(record)}}},[_vm._v("删除")]),(record.activity_type === 'public')?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(record.activity_type === 'public')?_c('a',{attrs:{"href":_vm.mallCouponActivity + record.custom_id,"target":"_blank"}},[_vm._v(" 访问链接 ")]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }